"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion, LayoutGroup } from "framer-motion";
import { useOutsideClick } from "@/hooks/use-outside-click";
import SparklesText from "@/components/magicui/sparkles-text";

import { Button } from "../../ui/button";
import Tag from "@/components/shared/Tag";

interface FeatureCardProps {
  title: string;
  position: string;
  imageSrc: string;
  description: string;
  content: () => React.ReactNode;
  tryNowLink: string;
  learnMoreLink: string;
}

const FeatureCard = ({
  title,
  position,
  imageSrc,
  description,
  content,
  tryNowLink,
  learnMoreLink,
}: FeatureCardProps) => {
  const [active, setActive] = useState(false);
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const activeCardRef = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setActive(false));
  useOutsideClick(activeCardRef, () => setActive(false));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setActive(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (active && activeCardRef.current) {
      activeCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [active]);

  return (
    <LayoutGroup>
      {!active && (
        <motion.div
          layoutId={`card-${title}-${id}`}
          onClick={() => setActive(true)}
          transition={{
            layout: { duration: 0.4, type: "spring", bounce: 0.1 },
          }}
          className={`absolute ${position} z-10 w-[190px] cursor-pointer items-start space-y-2 rounded-xl bg-white p-2.5 text-start shadow-[0_13px_22px_rgba(0,0,0,0.10)] transition-colors duration-300 hover:bg-[#E9F1FE] md:w-[350px] md:space-y-5 md:rounded-3xl md:p-5`}
        >
          <motion.div
            layoutId={`image-${title}-${id}`}
            transition={{ layout: { duration: 0.4, type: "spring", bounce: 0.1 } }}
            className="relative aspect-[580/360] w-full"
          >
            <Image
              src={imageSrc}
              alt={title}
              fill
              sizes="(max-width: 850px) 150px, 280px"
              className="rounded-xl border border-slate-200 object-cover"
            />
          </motion.div>
          <div className="flex w-full flex-row items-center justify-between space-x-2 md:space-x-4">
            <motion.h6
              layoutId={`title-${title}-${id}`}
              transition={{ layout: { duration: 0.4, type: "spring", bounce: 0.1 } }}
              className="w-[140px] text-[14px] font-bold leading-[110%] md:w-[205px] md:max-w-none md:text-[21px]"
            >
              {title}
            </motion.h6>
            <button className="rounded-full bg-crayoBlue/10 p-2 transition-all duration-200 hover:opacity-70">
              <Image
                src="https://cdn-crayo.com/lp/public/plusBlue.png"
                alt="view"
                width={8}
                height={8}
                className={`transition-transform duration-300`}
              />
            </button>
          </div>
        </motion.div>
      )}
      {active && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 z-20 h-full w-full bg-black/20 backdrop-blur-sm"
          />
          <motion.div
            layoutId={`card-${title}-${id}`}
            ref={activeCardRef}
            style={{
              position: "fixed",
              ...getPositionFromString(position),
            }}
            transition={{
              layout: { duration: 0.4, type: "spring", bounce: 0.1 },
            }}
            className="z-30 w-[500px] max-w-[90vw] overflow-hidden rounded-3xl bg-white dark:bg-neutral-900"
          >
            <motion.div
              layoutId={`image-${title}-${id}`}
              transition={{ layout: { duration: 0.4, type: "spring", bounce: 0.1 } }}
            >
              <Image
                priority
                width={480}
                height={360}
                src={imageSrc}
                alt={title}
                className="h-80 w-full object-cover object-top sm:rounded-tl-lg sm:rounded-tr-lg lg:h-80"
              />
            </motion.div>
            <div className="flex items-start justify-between p-8 text-start">
              <div>
                <motion.h3
                  layoutId={`title-${title}-${id}`}
                  transition={{ layout: { duration: 0.4, type: "spring", bounce: 0.1 } }}
                  className="text-[32px] font-bold text-neutral-800 dark:text-neutral-200"
                >
                  {title}
                </motion.h3>
                <motion.p className="h-30 flex flex-col items-start gap-4 overflow-auto pb-10 text-[21px] text-neutral-600 [mask:linear-gradient(to_bottom,white,white,transparent)] dark:text-neutral-400 md:h-fit">
                  {content()}
                </motion.p>
              </div>
            </div>
            <div className="relative p-8 pt-2 text-start">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex w-full flex-row items-center space-x-4"
              >
                <div className="w-full">
                  <Button variant="default" className="w-full space-x-2 py-4" href={tryNowLink}>
                    <div className="flex w-full items-center justify-center">
                      <Image
                        src="https://cdn-crayo.com/lp/public/lightningWhite.png"
                        alt="Lightning"
                        width={16}
                        height={16}
                      />
                      <span className="font-ambit-bold ml-2 text-white">Try now</span>
                    </div>
                  </Button>
                </div>
                {/* <Button
                  variant="secondary"
                  className="bg-slate-200 py-4 transition-all duration-200 hover:bg-slate-400"
                  href={learnMoreLink}
                >
                  Learn more
                </Button> */}
              </motion.div>
            </div>
          </motion.div>
        </>
      )}
    </LayoutGroup>
  );
};

function getPositionFromString(position: string) {
  const [vertical, horizontal] = position.split(" ");
  return {
    [vertical.split("-")[0]]: vertical.split("-")[1],
    [horizontal.split("-")[0]]: horizontal.split("-")[1],
  };
}

const features = [
  {
    title: "Splitscreen video",
    position: "top-[3%] left-[-1%] md:top-[0%] md:left-[10%]",
    imageSrc: "https://cdn-crayo.com/lp/public/features-video.png",
    description: "Create engaging splitscreen videos",
    content: () => (
      <p>
        Splitscreen videos allow you to show multiple scenes simultaneously, perfect for
        comparisons, reactions, or showcasing different perspectives.
      </p>
    ),
    tryNowLink: "https://crayo.ai/create/split-video?step=0",
    learnMoreLink: "/features/splitscreen-video",
  },
  {
    title: "Fake Texts video",
    position: "top-[3%] right-[-2%] md:top-[0%] md:right-[10%]",
    imageSrc: "https://cdn-crayo.com/lp/public/features-fake-text.png",
    description: "Generate realistic fake text overlays",
    content: () => (
      <p>
        Fake text overlays can be used to create captivating visuals and add a touch of humor or
        mystery to your videos.
      </p>
    ),
    tryNowLink: "https://crayo.ai/create/text-video?step=0",
    learnMoreLink: "/features/fake-texts-video",
  },
  {
    title: "Youtube downloader",
    position: "bottom-[-10%] left-[-1%] md:bottom-[0%] md:left-[10%]",
    imageSrc: "https://cdn-crayo.com/lp/public/features-downloader.png",
    description: "Download videos from YouTube easily",
    content: () => (
      <p>
        The YouTube downloader feature allows you to save videos from YouTube for offline use or
        editing.
      </p>
    ),
    tryNowLink: "https://crayo.ai/tools/youtube-downloader",
    learnMoreLink: "/features/youtube-downloader",
  },
  {
    title: "Voiceover story",
    position: "bottom-[-10%] right-[-2%] md:bottom-[0%] md:right-[10%]",
    imageSrc: "https://cdn-crayo.com/lp/public/features-voiceover.png",
    description: "Add professional voiceovers to your videos",
    content: () => (
      <p>
        Professional voiceovers can enhance the overall quality and professionalism of your videos.
      </p>
    ),
    tryNowLink: "https://crayo.ai/tools/voiceovers?step=0",
    learnMoreLink: "/features/voiceover-story",
  },
];

const Features = () => {
  return (
    <div className="flex flex-col items-center justify-between space-y-8 bg-gradient-to-b from-[#F0F7FF] to-white px-4 py-3 text-center md:space-y-12 md:px-40 md:py-16">
      <Tag label="FEATURES" icon="https://cdn-crayo.com/lp/public/iconLightbulb.png" />
      <div className="flex w-full flex-col space-y-2 px-4 md:space-y-4 md:px-20">
        <h3 className="text-[32px] leading-[120%] md:text-[48px]">
          Crayo has the tools to support any use-case for your short-form content.
        </h3>
      </div>
      <div className="align-center relative flex w-full flex-row items-center justify-center pt-16 md:pt-0 lg:pt-0">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
        <div className="p-18 align-center flex h-[350px] w-[350px] flex-row items-center justify-center rounded-full bg-crayoBlue/5 md:h-[750px] md:w-[750px]">
          <div className="md:p-18 align-center flex h-[275px] w-[275px] items-center justify-center rounded-full bg-crayoBlue/15 p-4 md:h-[600px] md:w-[600px]">
            <SparklesText
              className="text-[21px] font-bold leading-[120%] md:text-[32px]"
              text="Use countless viral-tested B-roll, overlays, fonts & more"
            />
          </div>
        </div>
      </div>
      <Button
        href="https://crayo.ai/dashboard"
        variant="default"
        className="mt-8 py-4 md:mt-0 lg:mt-0"
      >
        Try all features
      </Button>
    </div>
  );
};

export default Features;
