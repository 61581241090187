import React, { FC } from "react";
import Image from "next/image";
import ShineBorder from "@/components/magicui/shine-border"; 

interface TagProps {
    label: string;
    icon: string;
}

const Tag: FC<TagProps> = ({ label, icon }) => (
    <ShineBorder
        className="inline-flex items-center rounded-full px-3 py-1.5 sm:px-4 sm:py-2 bg-white bg-opacity-20"
        color={["#DFECFF", "#A8CBFF"]}
    >
        <Image src={icon} alt="" width={16} height={16} className="mr-2" />
        <span className="text-crayoBlue font-bold text-sm sm:text-base">{label}</span>
    </ShineBorder>
);

export default Tag;
